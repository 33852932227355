<template>
    <div class="animated fadeIn">
        <CCard>
            <CCardHeader><font-awesome-icon icon="pencil-alt"/> Field</CCardHeader>
            <CCardBody>
                
                <CTabs  :active-tab="my_tab" @update:activeTab="changeTab">
                            <br>  
                            <CTab>
                                <template slot="title">
                                    Active Field/s <CBadge color="success">( {{ [...new Set(field_lists_active.map((item) => item.display_name))].length }} )</CBadge>
                                </template>
                                <CDataTable
                                    :items="field_lists_active"
                                    :fields="field_list_fields"
                                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                    :items-per-page="10"
                                    border
                                    items-per-page-select
                                    sorter
                                    pagination
                                    :loading="this.table_loading"
                                >
                                    <template slot="no-items-view" v-if="this.table_loading">
                                        <center>
                                            <CSpinner
                                                style="width:4rem;height:4rem;"
                                                color="info"
                                            />
                                        </center>
                                    </template>
                                    <template #action="{item}">
                                        <td>
                                            <router-link :to="{path: `/${$store.getters['getOrganizationName']}/form-management/field/${item.id}`}">
                                                <CButton 
                                                    color="info" 
                                                    shape="pill" 
                                                    size="sm"
                                                >
                                                    <font-awesome-icon icon="cog"/> Manage
                                                </CButton>
                                            </router-link>
                                        </td>
                                    </template>
                                    <template #created_at="{item}">
                                        <td>
                                            {{changeDateFormatV2(item.created_at)}}
                                        </td>
                                    </template>
                                    <template #type="{item}">
                                        <td>
                                            {{item.field_type.name}}
                                        </td>
                                    </template>
                                    <template #validation="{item}">
                                        <td v-if="item.validation">
                                            {{item.validation}}
                                        </td>
                                        <td v-else>
                                            none
                                        </td>
                                    </template>
                                </CDataTable>
                            </CTab>
                            <CTab>
                                <template slot="title">
                                    Inactive Field/s <CBadge color="danger">( {{ [...new Set(field_lists_inactive.map((item) => item.display_name))].length }} )</CBadge>
                                </template>
                                <CDataTable
                                    :items="field_lists_inactive"
                                    :fields="field_list_fields"
                                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                    :items-per-page="10"
                                    border
                                    items-per-page-select
                                    sorter
                                    pagination
                                    :loading="this.table_loading"
                                >
                                    <template slot="no-items-view" v-if="this.table_loading">
                                        <center>
                                            <CSpinner
                                                style="width:4rem;height:4rem;"
                                                color="info"
                                            />
                                        </center>
                                    </template>
                                    <template #action="{item}">
                                        <td>
                                            <template v-if="$store.getters['can']('restore-field')">
                                                <CButton 
                                                        size="sm"
                                                        shape="pill"
                                                        color="success" 
                                                        :disabled="restoring ? (item.id == restoring_id ? true : false) : false"
                                                        @click="restore(item)"
                                                    >

                                                    <font-awesome-icon :icon="restoring ? (item.id == restoring_id ? 'circle-notch' : 'trash-restore') : 'trash-restore'" 
                                                                :pulse="restoring ? (item.id == restoring_id ? true : false) : false"/> Restore
                                                </CButton>
                                            </template>
                                            <template v-else>
                                                <i><b>No permission to restore.</b></i>
                                            </template>
                                        </td>
                                    </template>
                                    <template #updated_at="{item}">
                                        <td>
                                            <small>{{changeDateFormat(item.updated_at)}}</small>
                                        </td>
                                    </template>
                                    <template #type="{item}">
                                        <td>
                                            {{item.field_type.name}}
                                        </td>
                                    </template>
                                    <template #parameter_name="{item}">
                                        <td>
                                            {{item.parameter_name}}
                                        </td>
                                    </template>
                                    <template #validation="{item}">
                                        <td v-if="item.validation">
                                            {{item.validation}}
                                        </td>
                                        <td v-else>
                                            none
                                        </td>
                                    </template>
                                </CDataTable>
                            </CTab>
                    </CTabs>

            </CCardBody>
        </CCard>   
    </div>
</template>
<script>
export default {
    name: 'FieldLists',
    data(){
        return {
            table_loading: true,
            field_list: [],
            my_tab:0,
            restoring: false,
            restoring_id: null,
        }
    },
    created() {
        this.getFields();
        this.$emit('activeTab', 0);
    },
    computed: {
        field_lists_active: function() {
            return this.field_list.filter((item) => item.deleted_at == null);
        },
        field_lists_inactive: function() {
            return this.field_list.filter((item) => item.deleted_at != null);
        },

        field_list_fields: function () {
            let custom_fields= [
                { key: 'display_name', label: 'Name'},
                { key: 'parameter_name' },
                { key: 'type'},
                { key: 'validation' },
            ]

            if(this.my_tab) {
                custom_fields.push({ key: 'updated_at', label: 'Inactivity Date' })
            } else {
                custom_fields.push({ key: 'created_at', label: 'Date Added' })
            }


            if(this.$store.getters.can('view-field')) {
                custom_fields.push({ key: 'action', sorter: false})
            }
            return custom_fields;
        }
    },
    methods: {
        restore: function (item) {
            this.restoring = true
            this.restoring_id = item.id;
            this.$Progress.start()
            axios.post('/form-managements/field/restore/' + item.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${item.display_name} Field successfully restored.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.field_list.filter(obj => obj.id === item.id)[0].deleted_at = null;
                    // this.restoring = false
                    // this.restoring_id = null;
                    this.$Progress.finish()
                }
            })
        },
        changeTab: function (item) {
            this.my_tab = item
        },    
        getFields: function () {
            this.$Progress.start()
            axios.get('/form-managements/field/list', {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    this.field_list = response.data.data
                    this.table_loading = false;
                    this.$Progress.finish()
                }
            })
        },
    }
}
</script>